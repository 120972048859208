/* eslint-disable react-hooks/rules-of-hooks */
import { ReloadToken } from '../app/api/authenticate'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { selectUser, setUser } from '../app/slices/authSlice'
import {
  selectCurrentContract,
  setAllContracts,
  setCurrentContract
} from '../app/slices/contractSlice'
import Cookies from 'js-cookie'

const useReloadToken = () => {
  const dispatch = useAppDispatch()
  const user =
    useAppSelector(selectUser) ?? !localStorage.getItem('@lutocar-user-info')
      ? JSON.parse(localStorage.getItem('@lutocar-user-info')!)
      : null
  const currentContract = useAppSelector(selectCurrentContract)
  const identificadores = Cookies.get(
    `Inlog-identifiers-${process.env.REACT_APP_LUTOCARFRONT_NODE_ENV ?? ''}`
  )
  const inlogToken = Cookies.get(
    `Inlog-token-${process.env.REACT_APP_LUTOCARFRONT_NODE_ENV ?? ''}`
  )

  const handleReloadToken = async () => {
    const identificadoresJson =
      identificadores && JSON.parse(identificadores ?? '')
    const identifierToReloadToken = user?.codigo
      ? {
          identificadorUsuario: user?.identificadorAutenticador,
          identificadorConta: user?.conta?.identificadorAutenticador,
          tokenAutenticador: inlogToken ?? ''
        }
      : {
          identificadorUsuario: identificadoresJson?.identificadorUsuario,
          identificadorConta: identificadoresJson?.identificadorConta,
          tokenAutenticador: inlogToken ?? ''
        }
    const res = await ReloadToken(identifierToReloadToken)

    if (res.success) {
      const selectedContract =
        identificadoresJson?.identificadorContrato ??
        currentContract?.identificadorAutenticador

      let updateCurrentContractInfos = res?.data?.contratos?.find(
        (item) => item.identificadorAutenticador === selectedContract
      )
      if (!updateCurrentContractInfos) {
        updateCurrentContractInfos = res?.data?.contratos?.[0]
      }

      dispatch(setAllContracts(res?.data?.contratos!))
      dispatch(setCurrentContract(updateCurrentContractInfos!))
      if (res.data) {
        dispatch(setUser(res.data))
      }

      const tokenInfos = JSON.parse(res?.data?.token!)
      localStorage.setItem('@lutocar-token', tokenInfos.token)
      localStorage.setItem(
        '@lutocar-user-info',
        JSON.stringify({
          ...res?.data,
          tokenUsuarioImpersonate: user?.tokenUsuarioImpersonate
        })
      )
    }
    return res
  }

  return handleReloadToken
}

export default useReloadToken
