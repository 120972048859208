import axios, { AxiosResponse, AxiosError } from 'axios'
import Cookies from 'js-cookie'

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_LUTOCARFRONT_API_URL}/${process.env.REACT_APP_LUTOCARFRONT_API_URI}`
})

axiosInstance.interceptors.request.use(
  (config) => {
    let currentToken = null
    const inlogToken = Cookies.get(
      `Inlog-token-${process.env.REACT_APP_LUTOCARFRONT_NODE_ENV ?? ''}`
    )
    const tokenLocal = localStorage.getItem('@lutocar-token')
    const tokenImpersonateLocal = localStorage.getItem(
      '@lutocar-token-impersonate'
    )

    if (config?.url?.includes('RenovarTokenUsuario')) {
      currentToken = inlogToken ?? tokenLocal ?? tokenImpersonateLocal
    } else {
      currentToken = tokenLocal ?? tokenImpersonateLocal
    }

    config.headers['Authorization'] = currentToken
    config.headers['Accept'] = 'application/json'
    config.headers['Content-Type'] = 'application/json'
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['Access-Control-Allow-Methods'] =
      'GET, POST, PATCH, PUT, DELETE, OPTIONS'
    config.headers['Access-Control-Allow-Headers'] =
      'Origin, X-Requested-With, X-Auth-Token, Content-Type, Accept'
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response
  },
  async (error: AxiosError) => {
    if (error.response?.status === 403) {
      return await Promise.reject(Boolean(error) || {})
    } else if (error.response?.status === 400) {
      return await error.response
    } else {
      return await Promise.reject(error || {})
    }
  }
)

const axiosNoAuthInstance = axios.create({
  baseURL: `${process.env.REACT_APP_LUTOCARFRONT_API_URL}/${process.env.REACT_APP_LUTOCARFRONT_API_URI}`
})

axiosNoAuthInstance.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const apiNoAuth = axiosNoAuthInstance
export default axiosInstance
